.algo{
  height: 2px;
}

.raya {
  width: 310px;
  height: 6px;
  background-image: linear-gradient(to right, #94d9d3, #f5baa6, #a2b6b4);
  border-radius: 5em;
}

.divisorflex {
  display: flex;
  justify-content: center;
}
