#lastSections{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 2em;
}

@media only screen and (max-width: 1200px) {
    #lastSections{
        display: block;
        margin-bottom: 3em;
    }
}