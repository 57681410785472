.footer{
    display: flex;
    justify-content: center;
    background-color:#b0d6d3;
    padding: 2em;

}

#footer_links{
    display: flex;
    flex-direction: column;
    padding-left: 5em;
    border-left: 1px solid;
}

#footer_logo{
    padding-right: 5em;
}

@media only screen and (max-width: 1000px) {
    .footer{
        flex-direction: column;
    }
    #footer_links{
        padding-left: 0em;
        border-left: none;
        padding-top: 1em;
        border-top: 2px solid #34AEA4;
        font-size: 14px;
    }
    #footer_logo{
        padding-right: 0;
        margin-bottom: 0.5em;

    }
}