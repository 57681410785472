* {
  font-family: "Poppins", sans-serif;
  font-weight: 300; /* Light */
  padding: 0;
  color: #292A3B;
  margin: 0;
}

#tgroup_info {
  display: flex;
  justify-content: space-between;
}

#tgroup_full_info{
  margin: 0 70px;
}

#Tgroup_title {
  font-weight: 700; /* Light */
  font-size: 96px;
  /*background-image: linear-gradient(to right, #45BDB3, #E28668, #9EB0AF);*/
  background-image: linear-gradient(to right, #45BDB3, #E28668, #9EB0AF, #9EB0AF);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
}

#gradient {
  width: 100%;
  height: 800px;
  padding: 0px;
  margin: 0px;
}

#background_logo {
  position: relative;
  width: 160px;
  height: 160px;
  z-index: 1;
}
canvas {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 130px;
  height: 130px;
  background-image: linear-gradient(to right, #45BDB3, #E28668, #9EB0AF, #9EB0AF);
}
#img_logo_gr {
  position: absolute;
  top: 0;
  left: 0;
  width: 160px;
  height: 160px;
}

#loading_tgroup h4{
    position: relative;
    font-weight: 500; 
    font-size: 24px;
    color: #888888;
    z-index: 2;
    margin-top: -0.4em;
    width: 20em;

}
#botones_tgroup{
  display: flex;
  justify-content:center;
  margin-top: 2em;
}
#quienes_somos{
  margin-right: 0.75em;
}

#socios_estrategicos{
  margin-left: 0.75em;
}

#quienes_somos h5{
  font-size: 28px;
  font-weight: 500;
  color: #CECACA;

}
#quienes_somos a div{
  margin-top: 1em;
  width: 44vw;

  height: 9px;
  font-size: 9px;
  background-color: #CECACA;
  border-radius: 30px;
  color: #CECACA;
}

#quienes_somos a{
  text-decoration: none;
}

#socios_estrategicos h5{
  font-size: 28px;
  color: #CECACA;
  font-weight: 500;
}
#socios_estrategicos a div{
  margin-top: 1em;
  width: 44vw;
  height: 9px;
  font-size: 9px;
  background-color: #CECACA;
  border-radius: 30px;
  color: #CECACA;
}

#socios_estrategicos a{
  text-decoration: none;
}

#puntos_loading_text{
  font-weight: 500; 
  font-size: 24px;
  color: #ffffff;
  z-index: 2;
  margin-top: -0.4em;
  width: 20em;
}
#loading_tgroup{
    width:fit-content;
    text-align: center;
}

#flex_tgroup_logo{
    display: flex;
    justify-content: center;
}


.rotate {
  animation-name: rotate;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#textoInfo{
  display: flex;
  justify-content: center;
  margin-top: 2.5em;
  color: #292A3B;
  position: relative;
}

#textoInfo p span{
  font-weight: 500;
}

#textoInfo p{
  min-width: 70vw;

  width: 70vw;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
}

#circulo1
{
  height: 33px;
  width: 33px;
  border-radius: 20em;
  background-color: #E2704B;
  position: absolute;
  bottom: 0;
  right: 3em;
  transition: 0.5s;
}

#circulo2
{
  height: 27px;
  width: 27px;
  border-radius: 20em;

  background-color: #34AEA4;
  position: absolute;
  bottom: 2.5em;
  right: 1em;
  transition: 0.5s;


}
#textoInfo1[desaparecer="true"] {
  display: none;
}
#textoInfo2[desaparecer="true"] {
  display: none;

}

#textoInfo2[active="true"] {
  opacity: 0;
  transform: translateX(100%);
  animation: fadeInFromSide 1s ease forwards;
  display: block;
}

#textoInfo1[active="true"] {
  opacity: 0;
  transform: translateX(-100%);
  animation: fadeInFromSide 1s ease forwards;
  display: block;

}
#textoInfo1[active="false"] {
  opacity: 1;
  transform: translateX(0);
  animation: fadeOutToSide2 1s ease forwards;
}

#textoInfo2[active="true"] {
  opacity: 0;
  transform: translateX(100%);
  animation: fadeInFromSide 1s ease forwards;
}
#textoInfo2[active="false"] {
  opacity: 1;
  transform: translateX(0);
  animation: fadeOutToSide 1s ease forwards;
}
@keyframes desaparecer {
  to {
    display: none !important;
  }
}

@keyframes fadeInFromSide {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOutToSide {
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes fadeOutToSide2 {
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

#infoActive1[active="1"] div {
  background-color: #E2704B;
  color: #E2704B;
}

#infoActive2[active="2"] div {
  background-color: #34AEA4;
  color: #34AEA4;
}
#infoActive1[active="1"] h5 {
  color: #E2704B;
}

#infoActive2[active="2"] h5 {
  color: #34AEA4;
}

#quienes_somos a{
  text-decoration: none;
}

#circulo1[active="2"] {
  height: 27px;
  width: 27px;
  bottom: 2.5em;
  right: 1em;

}

#circulo2[active="2"] {
  height: 33px;
  width: 33px;
  bottom: 0;
  right: 3em;

}


@media only screen and (max-width: 900px) {
  #loading_tgroup {
    display: none;
  }
  #Tgroup_title {
    font-size: 70px;
  }
  #tgroup_full_info{
    margin: 0 30px;
    margin-bottom: 2em;

  }
  #botones_tgroup{
    justify-content:left;
    flex-direction: column;
    margin-top: 2em;
  }
  #textoInfo{
    margin-top: 1em;
    font-size: 20px;

  }
  #quienes_somos, #socios_estrategicos{
    margin-left: 0;
  }

  #quienes_somos a, #socios_estrategicos a {
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;
    margin-bottom: 1em;
    
  }
  #socios_estrategicos a div, #quienes_somos a div{
    margin-top: 0;
    width: 10px;
    height: 50px;
    margin-right: 1em;
  }
  

  #textoInfo p{
    width: 75vw;
    font-size: 20px;

  }
  #circulo1
{
  display: none;


}

#circulo2
{
  display: none;
}
}

@media only screen and (max-width: 350px) {
  #socios_estrategicos a div, #quienes_somos a div{
    margin-top: 0;
    width: 10px;
    height: 80px;
    margin-right: 1em;
  }
  
}