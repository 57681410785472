* {
  font-family: "Poppins", sans-serif;
  font-weight: 300; /* Light */
  padding: 0;
  color: #292A3B;
  margin: 0;
}

.flex_container{
  display: flex;
  justify-content: center;
  -webkit-box-shadow: 0 4px 8px -6px #222;
  -moz-box-shadow: 0 4px 8px -6px #222;
  box-shadow: 0 4px 8px -6px #222;
}
.navbar{
  width: 95%;
  height: 3em;
  background-color: white;
  border-radius: 2em;
  padding: 1em 1.5em;
}

.navbar_item{
  float: right;
  padding: 0 1em;
  font-size: 1.1em;
  padding-top: 0.75em;
  height: 100%;
  text-decoration: none;
  color: #34AEA4;
  cursor: pointer;
}

.navbar_item1{
  background-color:#34AEA4;
  border-radius: 30px;
  float: right;
  padding: 0 1em 0.75em 1em ;
  font-size: 1.1em;
  padding-top: 0.75em;
  text-decoration: none;
  color: white;
  cursor: pointer;
}



@media (max-width: 700px) {
  .navbar_item{
    display: none;
  } 
  .navbar_item1{
    display: none;
  } 
  .navbar{
    display: flex;
    justify-content: center;
  }
}

