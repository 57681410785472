* {
  font-family: "Poppins", sans-serif;
  font-weight: 300; /* Light */
  padding: 0;
  color: #292A3B;
  margin: 0;
}

#soluciones{
  margin: 0 17vw;
  margin-top: 2em;
}
#soluciones h2{
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 1em;
}

#solucionesCards{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.cardDiv{
  width: 626px;
  border-radius: 40px;
  margin: 1em 0;
  position: relative;
}

.verde{
  background-color: #34AEA4;
  color: white;
}
.azul{
  background-color: #222335;
  color: white;

}
.gris{
  background-color: #E2E2E2;
}

.verde div article ul li, .azul div article ul li{
  color: white;

}

.gris div h4, .gris div p{
  color: #292A3B;
}

@media (max-width: 1600px) {
  #solucionesCards{

    justify-content: space-evenly;
  }
  #soluciones{
    margin: 2em 10.5vw;
  }
}

@media (max-width: 800px) {

#soluciones{
  margin: 1em;
  margin-top: 1.5em;
}
#soluciones h2{
  margin-bottom: 0.25em;
}
}